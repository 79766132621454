import { compareData } from '@/../server/utils/compare';
import router from '../router';

export default {
    // ROOMS
    init({ state, dispatch, commit }) {
        const socket = state.socket;

        socket.on('roomJoined', id => dispatch('handleRoomJoined', id));
        socket.on('playersChanged', players => commit('setPlayers', players));
        socket.on('songSelected', song => commit('setCurrentSong', song));
        socket.on('roomClosed', () => dispatch('handleRoomClosed'));
        socket.on('emptyRoom', () => dispatch('handleEmptyRoom'));
        socket.on('songsList', songs => commit('setSongs', songs));
        socket.on('loadSong', song => dispatch('handleLoadSong', song));
        socket.on('error', message => commit('setError', message));
        socket.on('gameStarted', () => { dispatch('handleGameStarted', true); });
    },

    handleGameStarted({ commit }) {
        commit('setGameStarted', true);
        if (router.currentRoute.value.name === 'Host') {
            router.push({ name: 'Video' });
        } else {
            router.push({ name: 'Game' });
        }
    },

    handleEmptyRoom({ commit, dispatch }) {
        commit('setJoining', false);
        commit('setError', 'Der Raum existiert nicht. Bitte überprüfen Sie die Raum-ID.');
        dispatch('resetRoomState');
        router.push('/login');
        localStorage.removeItem('lastConnect');
    },


    handleRoomJoined({ commit }, id) {
        commit('setJoinedRoom', true);
        commit('setRoomId', id);
        commit('setJoining', false);
        router.push('/room');
        localStorage.setItem('lastConnect', id);
        commit('setError', '');
    },

    handleRoomClosed({ dispatch, commit }) {
        commit('setJoining', false);

        commit('setError', `Der Host hat die Verbindung getrennt. Du wurdest aus dem Raum entfernt.`);
        dispatch('leaveRoom');
    },

    handleLoadSong({ commit }, song) {
        commit('setSongId', song.id);
        delete song.songId;
        const dataArray = Object.keys(song).map(key => song[key]);
        commit('setServerData', dataArray);
    },

    resetRoomState({ commit }) {
        commit('setGameStarted', false);
        commit('setSongId', null);
        commit('setRoomId', null);
        commit('setJoinedRoom', false);
        commit('setPlayers', []);
    },

    joinRoom({ state, commit }, { textRoomId }) {
        if (state.username && textRoomId) {
            const roomId = textRoomId.toLowerCase();
            commit('setJoining', true);
            state.socket.emit('joinRoom', { roomId, username: state.username });
        } else {
            commit('setError', 'Bitte füllen Sie alle Felder aus.');
        }
    },

    reconnect({ commit, dispatch }) {
        const lastConnect = localStorage.getItem('lastConnect');
        if (lastConnect) {
            const username = localStorage.getItem('username');
            if (username) {
                commit('setUsername', username);
                dispatch('joinRoom', { textRoomId: lastConnect });
            }
        } else {
            router.push('/login');
        }
    },

    selectSong({ state }, songId) {
        state.socket.emit('selectSong', { roomId: state.roomId, songId: songId ? songId.id : null });
    },

    leaveRoom({ commit, dispatch, state }) {
        const roomId = state.roomId;
        if (roomId) {
            state.socket.emit('leaveRoom', roomId);
        }
        commit('setJoining', false);
        dispatch('selectSong', null);
        dispatch('resetRoomState');
        router.push('/login');
        localStorage.removeItem('lastConnect');
    },

    // RECORD
    startRecording({ commit, state, dispatch }) {
        commit('setIsRecording', true);
        commit('setElapsedTime', 0);
        commit('setRecordData', []);

        state.startTime = Date.now();
        state.recordingInterval = setInterval(() => {
            const record = dispatch('generateRecord');
            commit('addRecordData', record);
        }, 200);
    },

    stopRecording({ commit, state }) {
        clearInterval(state.recordingInterval);
        commit('setIsRecording', false);

        if (state.serverData.length > 0 && state.recordData.length > 0) {
            const comparisonResult = compareData(state.recordData, state.serverData);
            console.log(comparisonResult);
            commit('setIsDanceSame', comparisonResult.isDanceSame);
            commit('setSensorResults', comparisonResult.sensorResults);
            commit('setPercentageDifference', {
                percentageDifference: comparisonResult.percentageDifference
            });
        }
    },

    saveServerData({ commit, state }) {
        let copy = JSON.parse(JSON.stringify(state.recordData));
        copy = { ...copy, songId: state.songId };
        state.socket.emit('saveServerData', copy);

        commit('setRecordData', []);
    },

    loadServerData({ state }) {
        state.socket.emit('loadServerData', state.songId);
    },

    getAllSongs({ state }) {
        state.socket.emit('getAllSongs');
    },

    startGame({ state }) {
        state.socket.emit('startGame', { roomId: state.roomId });
    },

    startRealTimeComparison({ commit, state, dispatch }) {
        state.startTime = Date.now();

        const recordingInterval = setInterval(() => {
            const record = dispatch('generateRecord');
            commit('addRecordData', record);
            state.socket.emit('realTimeData', { recordData: record, songId: state.songId, playerId: state.username });
        }, 200);
        commit('setRealTimeRecordingInterval', recordingInterval);

        state.socket.on('realTimeComparisonResult', (result) => {
            if (result.playerId === state.username) {
                commit('setIsDanceSame', result.comparisonResult.isDanceSame);
                commit('setSensorResults', result.comparisonResult.sensorResults);
                commit('setPercentageDifference', {
                    percentageDifference: result.comparisonResult.percentageDifference
                });
            }
        });
    },

    stopRealTimeComparison({ state }) {
        clearInterval(state.realTimeRecordingInterval);
        clearInterval(state.realTimeComparisonInterval);
        state.socket.off('realTimeComparisonResult');
    },

    generateRecord({ state }) {
        const currentTime = Date.now();
        const elapsedTime = ((currentTime - state.startTime) / 1000).toFixed(2);
        return {
            timestamp: parseFloat(elapsedTime),
            gyroscope: { ...state.currentGyroscope },
            accelerator: { ...state.currentAccelerator },
            rotation: { ...state.rotation },
        };
    },

    findClosestServerRecords({ state }, recordData) {
        return recordData.map(record => {
            let closestRecord = state.serverData.find(serverRecord => serverRecord.timestamp === record.timestamp);

            if (!closestRecord) {
                closestRecord = state.serverData.reduce((closest, current) => {
                    const closestDiff = Math.abs(closest.timestamp - record.timestamp);
                    const currentDiff = Math.abs(current.timestamp - record.timestamp);
                    return currentDiff < closestDiff ? current : closest;
                });
            }

            return closestRecord;
        });
    },
}
