export default {

    setSocket(state, socket) {
        state.socket = socket;
    },
    //GAME
    setSongs(state, songs) {
        state.songs = songs;
    },
    setCurrentSong(state, song) {
        state.currentSong = song;
    },

    //ROOMS
    setJoinedRoom(state, joinedRoom) {
        state.joinedRoom = joinedRoom;
    },
    setRoomId(state, roomId) {
        state.roomId = roomId;
    },
    setPlayers(state, players) {
        state.players = players;
    },
    setUsername(state, username) {
        state.username = username;
    },
    setError(state, error) {
        state.error = error;
    },
    setJoining(state, joining) {
        state.joining = joining;
    },

    //GYROSCOPE
    setGyroscope(state, payload) {
        state.currentGyroscope = payload;
    },
    setAccelerator(state, payload) {
        state.currentAccelerator = payload;
    },
    setRotation(state, payload) {
        state.rotation = payload;
    },

    //RECORD
    setRecordData(state, payload) {
        state.recordData = payload;
    },
    addRecordData(state, payload) {
        state.recordData.push(payload);
    },
    setServerData(state, payload) {
        state.serverData = payload;
    },
    setIsRecording(state, payload) {
        state.isRecording = payload;
    },
    setElapsedTime(state, payload) {
        state.elapsedTime = payload;
    },
    setSongId(state, payload) {
        state.songId = payload;
    },

    //Compare
    setIsDanceSame(state, payload) {
        state.isDanceSame = payload;
    },
    setPercentageDifference(state, payload) {
        state.percentageDifference = payload.percentageDifference;
    },
    setSensorResults(state, payload) {
        state.sensorResults = payload;
    },

    //Game
    setRealTimeRecordingInterval(state, interval) {
        state.realTimeRecordingInterval = interval;
    },
    setRealTimeComparisonInterval(state, interval) {
        state.realTimeComparisonInterval = interval;
    },
    setGameStarted(state, started) {
        state.isGameStarted = started;
    },
}