import { createRouter, createWebHashHistory } from 'vue-router';

function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|Android|iPad|iPhone|iPod/.test(userAgent);
}

const routes = [
  {
    path: '/',
    redirect: () => {
      return isMobile() ? '/login' : '/host';
    },
  },
  {
    path: '/login/:roomId?',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
  },
  {
    path: '/room/',
    name: 'Room',
    component: () => import(/* webpackChunkName: "waiting" */ '../views/WaitingView.vue'),
  },
  {
    path: '/host',
    name: 'Host',
    component: () => import(/* webpackChunkName: "host" */ '../views/HostView.vue'),
  },
  {
    path: '/record',
    name: 'Record',
    component: () => import(/* webpackChunkName: "record" */ '../views/RecordView.vue'),
  },
  {
    path: '/game/',
    name: 'Game',
    component: () => import(/* webpackChunkName: "game" */ '../views/GameView.vue'),
  },
  {
    path: '/video/',
    name: 'Video',
    component: () => import(/* webpackChunkName: "video" */ '../views/VideoView.vue'),
  }

];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
