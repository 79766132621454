export default {
    socket: null,
    //GAME
    songs: [],
    currentSong: null,
    isGameStarted: false,
    // Rooms
    players: [],
    roomId: null,
    username: "",
    error: "",
    joinedRoom: false,
    joining: false,
    // Gyroscope
    currentGyroscope: { x: 0, y: 0, z: 0 },
    currentAccelerator: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    // Record
    recordData: [],
    serverData: [],

    songId: null,
    isRecording: false,
    elapsedTime: 0,

    startTime: 0,
    recordingInterval: null,
    //Compare
    sensorResults: null,
    isDanceSame: false,
    percentageDifference: 0,
    realTimeComparisonInterval: null
};