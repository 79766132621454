<template>
  <router-view />
</template>

<script>
import io from 'socket.io-client';

export default {
  created() {
    if (location.protocol !== 'https:') {
      location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }

    let socket = io('jserver.alexanderwick.com:3000');
    this.$store.commit('setSocket', socket);

    this.$store.dispatch('init');

    window.addEventListener('beforeunload', this.disconnect);
  },
  unmounted() {
    this.disconnect();
  },
  methods: {
    disconnect() {
      this.$store.state.socket.disconnect();
    }
  }
};

</script>

<style>
.m-5 {
  margin: 0px 5px;
}

body,
html {
  margin: 0;
  padding: 0;
}
</style>
