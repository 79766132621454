const TOLERANCES = {
    gyroscope: {
        mean: 13,
        max: 27,
        min: 27,
        standardDeviation: 8
    },
    accelerator: {
        mean: 1,
        max: 2,
        min: 2,
        standardDeviation: 1
    },
    rotation: {
        mean: 12,
        max: 22,
        min: 22,
        standardDeviation: 8
    }
};

function compareData(recordData, serverData) {
    const FEATURETOLERANCE = 20;
    const AXES = ['x', 'y', 'z'];
    const SENSORS = ['gyroscope', 'accelerator', 'rotation'];
    const WINDOW_SIZE = 5;

    const recordAxesData = {};
    const serverAxesData = {};

    SENSORS.forEach(sensor => {
        recordAxesData[sensor] = {};
        serverAxesData[sensor] = {};
        AXES.forEach(axis => {
            let recordAxisData = extractAxis(recordData.map(r => r[sensor]), axis);
            let serverAxisData = extractAxis(serverData.map(s => s[sensor]), axis);

            // Daten glätten
            recordAxisData = smoothData(recordAxisData, WINDOW_SIZE);
            serverAxisData = smoothData(serverAxisData, WINDOW_SIZE);

            // Merkmale extrahieren
            recordAxesData[sensor][axis] = extractFeatures(recordAxisData);
            serverAxesData[sensor][axis] = extractFeatures(serverAxisData);
        });
    });

    const sensorResults = SENSORS.reduce((results, sensor) => {
        results[sensor] = AXES.reduce((axisResults, axis) => {
            const recordFeatures = recordAxesData[sensor][axis];
            const serverFeatures = serverAxesData[sensor][axis];
            const { isMatch, differences } = compareFeatures(recordFeatures, serverFeatures, sensor);
            axisResults[axis] = { isMatch, differences };
            return axisResults;
        }, {});
        return results;
    }, {});

    // Berechnung der Anzahl der Unterschiede und der prozentualen Differenz
    let totalFeatures = 0;
    let matchingFeatures = 0;

    SENSORS.forEach(sensor => {
        AXES.forEach(axis => {
            Object.values(sensorResults[sensor][axis].differences).forEach(diff => {
                totalFeatures += 1;
                if (diff.withinTolerance) {
                    matchingFeatures += 1;
                }
            });
        });
    });

    const isDanceSame = matchingFeatures >= FEATURETOLERANCE;
    const percentageDifference = "(" + matchingFeatures + " / " + totalFeatures + ")"

    return {
        isDanceSame,
        percentageDifference,
        sensorResults,
    };
}

// Hilfsfunktionen
function extractAxis(data, axis) {
    return data.map(entry => entry[axis]);
}

// Gleitender Durchschnitt
function smoothData(data, windowSize) {
    return data.map((val, index, arr) => {
        const start = Math.max(0, index - Math.floor(windowSize / 2));
        const end = Math.min(arr.length, index + Math.floor(windowSize / 2) + 1);
        const subset = arr.slice(start, end);
        const sum = subset.reduce((acc, curr) => acc + curr, 0);
        return sum / subset.length;
    });
}

/* Alternativer Medianfilter
function medianFilter(data, windowSize) {
    return data.map((val, index, arr) => {
        const start = Math.max(0, index - Math.floor(windowSize / 2));
        const end = Math.min(arr.length, index + Math.floor(windowSize / 2) + 1);
        const subset = arr.slice(start, end).sort((a, b) => a - b);
        const median = subset[Math.floor(subset.length / 2)];
        return median;
    });
} */

function extractFeatures(data) {
    const mean = data.reduce((acc, curr) => acc + curr, 0) / data.length;
    const max = Math.max(...data);
    const min = Math.min(...data);
    const variance = data.reduce((acc, curr) => acc + Math.pow(curr - mean, 2), 0) / data.length;
    const standardDeviation = Math.sqrt(variance);

    return { mean, max, min, standardDeviation };
}

function compareFeatures(recordFeatures, serverFeatures, sensorType) {
    const differences = {};
    let isMatch = true;

    Object.keys(recordFeatures).forEach(key => {
        const tolerance = TOLERANCES[sensorType][key];
        const difference = Math.abs(recordFeatures[key] - serverFeatures[key]);
        const withinTolerance = difference <= tolerance;
        differences[key] = {
            recordValue: recordFeatures[key],
            serverValue: serverFeatures[key],
            difference: difference,
            withinTolerance: withinTolerance
        };
        if (!withinTolerance) {
            isMatch = false;
        }
    });

    return {
        isMatch,
        differences
    };
}

module.exports = {
    compareData
};